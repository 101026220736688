import { z } from 'zod'
import { openGovEmailSchema } from './common'

export const emailSchema = z.object({
  email: openGovEmailSchema,
})

export type EmailSchema = z.infer<typeof emailSchema>

export const otpSchema = z.object({
  otp: z
    .string({ required_error: 'Please enter an OTP.' })
    .trim()
    .min(1, 'OTP is required.')
    .regex(/^[0-9\b]+$/, { message: 'Only numbers are allowed.' })
    .length(6, 'Please enter a 6 digit OTP.'),
})

export type OtpSchema = z.infer<typeof otpSchema>
