import { z } from 'zod'

const OPEN_GOV_EMAILS = /^[^@\s]+@open\.gov\.sg$/

export const openGovEmailSchema = z
  .string({ required_error: 'Please enter an email address.' })
  .trim()
  .min(1, 'Please enter an email address.')
  .email({ message: 'Please enter a valid email address.' })
  .regex(OPEN_GOV_EMAILS, { message: 'Please enter an @open.gov.sg email address.' })
