'use client'

import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'

import { Form, FormControl, FormField, FormItem, FormMessage } from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import LoadingButton from '@/components/loading-button'

import { EmailSchema, emailSchema } from '@/validators/login'
import { sendOtpForEmail } from '@/actions/otp'
import LoginHeader from './login-header'
import { submitActionInForm } from '@/helpers/submitActionInForm'

type EmailFormProps = {
  setEmail: (email: string) => void
}

export default function EmailForm({ setEmail }: EmailFormProps) {
  const form = useForm<EmailSchema>({
    resolver: zodResolver(emailSchema),
  })

  const onSubmit = (data: EmailSchema) =>
    submitActionInForm(sendOtpForEmail, { email: data.email }, (res) => {
      setEmail(data.email)
    })

  return (
    <div className='w-full items-center space-y-4'>
      <LoginHeader text='Enter your email below to receive an OTP' />
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className='space-y-4'>
          <FormField
            control={form.control}
            name='email'
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input placeholder='sample@open.gov.sg' {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <LoadingButton
            isLoading={form.formState.isSubmitting}
            text='Send OTP'
            className='w-full'
          />
        </form>
      </Form>
    </div>
  )
}
