'use client'

import { useState } from 'react'
import EmailForm from './email-form'
import OtpForm from './otp-form'

export default function LoginForm() {
  const [email, setEmail] = useState<string | null>(null)

  return (
    <div className='mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[400px]'>
      {email ? <OtpForm email={email} /> : <EmailForm setEmail={setEmail} />}
    </div>
  )
}
