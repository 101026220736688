import { Button } from './ui/button'

type LoadingButtonProps = {
  isLoading: boolean
  text: string
  onClick?: () => void
  className?: string
}

export default function LoadingButton({ isLoading, text, onClick, className }: LoadingButtonProps) {
  return (
    <Button disabled={isLoading} type='submit' className={className} onClick={onClick}>
      {isLoading && (
        <div className='mr-2 inline-block h-4 w-4 animate-spin rounded-full border-2 border-current border-t-transparent' />
      )}
      {text}
    </Button>
  )
}
