import { toast } from '@/components/ui/use-toast'

type ActionFunction<T, R> = (requestData: T) => Promise<R>
type OnSuccessFunction<R> = (response: R) => void

export function submitActionInForm<T, R>(
  requestFunc: ActionFunction<T, R>,
  requestData: T,
  onSuccess?: OnSuccessFunction<R>
): Promise<void> {
  return requestFunc(requestData)
    .then((res: any) => {
      if (res?.error) {
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: res.error,
        })
      } else {
        if (onSuccess) {
          onSuccess(res)
        }
        return
      }
    })
    .catch((err: Error) => {
      toast({
        variant: 'destructive',
        title: 'Unknown Error',
        description: 'There was an unknown error. Please try again.',
      })
      console.error(err)
    })
}
