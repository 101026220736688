'use client'

import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'

import { Form, FormControl, FormField, FormItem, FormMessage } from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import LoadingButton from '@/components/loading-button'

import { OtpSchema, otpSchema } from '@/validators/login'
import { verifyOtpForEmail } from '@/actions/otp'
import LoginHeader from './login-header'
import { submitActionInForm } from '@/helpers/submitActionInForm'

type OtpFormProps = {
  email: string
}

export default function OtpForm({ email }: OtpFormProps) {
  const form = useForm<OtpSchema>({
    resolver: zodResolver(otpSchema),
  })

  const onSubmit = async (data: OtpSchema) =>
    submitActionInForm(verifyOtpForEmail, { email, token: data.otp })

  return (
    <div className='w-full items-center space-y-4'>
      <LoginHeader text={`Enter the OTP delivered to ${email}`} />
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className='space-y-4'>
          <FormField
            control={form.control}
            name='otp'
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <LoadingButton isLoading={form.formState.isSubmitting} text='Login' className='w-full' />
        </form>
      </Form>
    </div>
  )
}
