type LoginHeaderProps = {
  text: string
}

export default function LoginHeader({ text }: LoginHeaderProps) {
  return (
    <div className='flex flex-col items-center space-y-4'>
      <h1 className='text-2xl font-semibold tracking-tight'>
        <span className='text-primary underline'>@open.gov.sg</span> login
      </h1>
      <h2 className='text-sm text-muted-foreground'>{text}</h2>
    </div>
  )
}
